<template>
  <div>
    <base-header class="pb-1">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0"></h6>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <form>
        <div class="col-md-12">
          <card>
            <h3 slot="header" class="mb-0">Ratificações Pendentes</h3>
            <h5 class="text-center mb-2">
              Encontradas <strong>{{registryFound}}</strong> ratificações pendentes
            </h5>
            <table class="table table-responsive font-tabela-peq">
              <thead class="thead">
                <tr>
                  <th class="text-center" scope="col">Tipo</th>
                  <th scope="col">Usuário</th>
                  <th class="text-center" scope="col">Data da Execução</th>
                  <th scope="col">Funcionalidade</th>
                  <th scope="col">Cliente</th>
                  <th scope="col">Alteração</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="rat in ratifications" :key="rat.id">
                  <td>
                    {{rat.typeFunctionality}}
                  </td>
                  <td>{{rat.user.name}}</td>
                  <td class="text-center">{{rat.updatedAt}}</td>
                  <td>{{rat.functionality}}</td>
                  <td>{{rat.holder.name}}</td>
                  <td>{{rat.action}}. <span v-if="rat.functionality == 'ESTORNAR_FATURA'">Valor: {{rat.amountFmt}}</span> </td>
                  <td>
                    <a href="#" @click.prevent="approval(rat.id)">
                      <el-tooltip content="Aprovar" placement="top">
                        <i style="font-size: 21px;" class="text-success far fa-check-circle"></i>
                      </el-tooltip>
                    </a> &nbsp;
                    <a href="#" @click.prevent="openModalDenied(rat.id)">
                      <el-tooltip content="Negar" placement="top">
                        <i style="font-size: 21px;" class="text-danger far fa-times-circle"></i>
                      </el-tooltip>
                    </a> &nbsp;
                    <!--
                    <a href="#" title="Negar" @click.prevent="action(rat.id, 'deny')">
                      <i class="far fa-times-circle"></i>
                    </a>
                    -->
                  </td>
                </tr>
                <tr v-show="ratifications.length == 0">
                  <td colspan="8">
                    <center>Nenhuma ratificação pendente.</center>
                  </td>
                </tr>
              </tbody>
            </table>         
          </card>
        </div>
      </form>
    </div>
    <!--- fecha conteudo -->

    
    <!-- modal justificativa -->    
    <modal :show.sync="modals.modalDenied">
      <form class="ml-3 mt-3 mr-3 mb-3">
      <h4 class="text-center">Negar execução</h4>
        <label class="mt-4 form-control-label">Justificativa</label>
        <base-input
            name="justificativa"
            maxlength="255"
            placeholder="Justificativa"
            v-model="ratification.justification">
        </base-input>
      <br class="clear"/>
      <div class="col-md-12 text-center mt-3">
        <button type="button" class="btn btn-primary" @click="denied()">Confirmar</button>
        <button type="button" class="btn btn-secundary ml-4" @click="modals.modalDenied = false">Desistir</button>
      </div>  
      </form>   
  </modal> 
  
  </div>
</template>
<script>
export default {
  mounted() {
    this.loadRatifications();
  },
  data() {
    return {
      modals : {
          modalDenied : false,
      },
      ratifications: [],
      ratification: {
        id: '',
        justification: '',
      },
      registryFound: 0
    };
  },
  methods: {
    openModalDenied(id) {
      this.ratification.id = id;
      this.ratification.justification = '';
      this.modals.modalDenied = true;
    },
    approval(id) {
      this.ratification.id = id;
      this.$clubApi.put("/ratification/approval", this.ratification)
        .then(response => {
          this.$notify({type: 'success', message: "Ratificação aprovada com sucesso!"})
          this.loadRatifications();
        })
        .catch(error => {
          this.$notify({ type: "warning", message: error.response.data.msg });
        })
        .finally(() => {
          NProgress.done();
      });
    },
    denied() {
      this.modals.modalDenied = false
      this.$clubApi.put("/ratification/deny", this.ratification)
        .then(response => {
          this.$notify({type: 'success', message: "Ratificação negada com sucesso!"})
          this.loadRatifications();
        })
        .catch(error => {
          this.$notify({ type: "warning", message: error.response.data.msg });
        })
        .finally(() => {
          NProgress.done();
      });
    },
    loadRatifications() {
      this.$clubApi.get("/ratification")
        .then(response => {
            this.ratifications = response.data.object;
            this.registryFound = response.data.object.length;      
        })
        .catch(error => {
          this.$notify({ type: "warning", message: error.response.data.msg });
        })
        .finally(() => {
          NProgress.done();
        });
    }
  }
};
</script>
<style></style>
